import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import LoadingModal from '../../common/components/loading-modal/LoadingModal';
import { useAddSplitTransactionMutation, useDoneSplitTransactionMutation, useMoveItemSplitTransactionMutation } from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { setTransaction, useTransaction } from '../order/orderSlice';

import SplitOrder from './SplitOrder';
import { clearSplit, setVisibleSplitIndex, useActiveTransactionLineItem, useVisibleSplitIndex } from './splitSplice';

import './styles.sass';

function Split() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const visibleSplitIndex = useVisibleSplitIndex();
  const activeTransactionLineItem = useActiveTransactionLineItem();

  const operatorSession = useOperatorSession();
  const transactions = useTransaction();
  const [triggerAddSplit] = useAddSplitTransactionMutation();
  const [triggerMoveItem] = useMoveItemSplitTransactionMutation();
  const [triggerSplitDone] = useDoneSplitTransactionMutation();

  const onDoneClick = () => {
    if (transactions && transactions.length > 0) {
      triggerSplitDone(transactions[0].id)
        .unwrap()
        .then((data) => {
          dispatch(clearSplit());
          dispatch(setTransaction(data));
          navigate('/tender');
        })
        .catch(() =>
          dispatch(
            showErrorDialog({
              message: 'An error occurred completing split',
              dismissible: true,
              buttonText: 'OK',
            }),
          ),
        );
    }
  };

  useEffect(() => {
    if (transactions) if (visibleSplitIndex === 0 && transactions.length > 1) dispatch(setVisibleSplitIndex(1));
  }, [transactions, dispatch, visibleSplitIndex]);

  if (transactions === undefined) return <LoadingModal />;

  const addSplit = () => {
    triggerAddSplit(transactions[0].id)
      .unwrap()
      .then((data) => dispatch(setTransaction(data)))
      .catch((err) => dispatch(showErrorDialog({ message: err, dismissible: true })));
  };

  const moveItemLeft = () => {
    if (activeTransactionLineItem) {
      triggerMoveItem({
        transactionLineItemId: activeTransactionLineItem.id,
        newTransactionId: transactions[0].id,
      })
        .unwrap()
        .then((data) => dispatch(setTransaction(data)))
        .catch((err) => {
          if (err.status === 424) dispatch(showErrorDialog({ message: err.data.error, dismissible: true }));
          else dispatch(showErrorDialog({ message: JSON.stringify(err), dismissible: true }));
        });
    }
  };
  const moveItemRight = () => {
    if (activeTransactionLineItem) {
      if (transactions[0].transactionLineItems.length > 1) {
        triggerMoveItem({
          transactionLineItemId: activeTransactionLineItem.id,
          newTransactionId: transactions[visibleSplitIndex].id,
        })
          .unwrap()
          .then((data) => dispatch(setTransaction(data)))
          .catch((err) => dispatch(showErrorDialog({ message: JSON.stringify(err), dismissible: true })));
      } else {
        dispatch(
          showErrorDialog({
            message: 'You must have at least one item left on the main transaction',
            dismissible: true,
          }),
        );
      }
    }
  };

  return (
    <div className="pos-container pos-container_role_split">
      <div className="pos-split">
        <SplitOrder splitNumber={1} transaction={transactions[0]} totalSplits={transactions.length} />
        <div className="pos-split__dashboard">
          <div className="pos-split__actions">
            <button className="pos-split__action pos-split__action_role_add" type="button" onClick={addSplit}>
              + Another Split
            </button>
          </div>
          <div className="pos-split__controls">
            <button
              className="pos-split__control pos-split__control_role_add-to"
              type="button"
              onClick={moveItemRight}
              disabled={
                activeTransactionLineItem === undefined ||
                transactions[0].transactionLineItems.find((x) => x.id === activeTransactionLineItem.id) === undefined
              }
            >
              Add to Split
            </button>
            <button
              className="pos-split__control pos-split__control_role_take-off"
              type="button"
              onClick={moveItemLeft}
              disabled={
                activeTransactionLineItem === undefined ||
                transactions[visibleSplitIndex].transactionLineItems.find((x) => x.id === activeTransactionLineItem.id) === undefined
              }
            >
              Take off Split
            </button>
          </div>
          <div className="pos-split__actions">
            <button className="pos-split__action pos-split__action_role_done" type="button" onClick={onDoneClick}>
              Done
            </button>
          </div>
        </div>
        {transactions.length === 1 && <div className="pos-split__order"></div>}
        {transactions.length > 1 && (
          <SplitOrder transaction={transactions[visibleSplitIndex]} splitNumber={visibleSplitIndex + 1} totalSplits={transactions.length} />
        )}
      </div>
    </div>
  );
}

export default Split;
