import { useNavigate } from 'react-router-dom';

import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch } from '../../app/redux/hooks';
import LoadingModal from '../../common/components/loading-modal/LoadingModal';
import Price from '../../common/components/price/Price';
import { usePrintTransactionMutation } from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { setActiveTransactionLineItem, useTransaction } from '../order/orderSlice';
import { clearSplit } from '../split/splitSplice';

import { setActiveTransactionId, useActiveTransactionId } from './tenderSlice';

import 'swiper/css/bundle';

function TenderOrderPanel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();
  const transaction = useTransaction();
  const activeTransactionId = useActiveTransactionId();

  const [triggerPrint] = usePrintTransactionMutation();

  const receiptClicked = () => {
    if (operatorSession && activeTransactionId !== undefined) {
      triggerPrint({
        operatorId: operatorSession.operator.id,
        transactionId: activeTransactionId,
      })
        .unwrap()
        .catch((err) => dispatch(showErrorDialog({ message: err.data.detail, dismissible: true })));
    }
  };

  const setPreviousSplit = () => {
    if (activeTransactionId) {
      const transactionIdList = transaction.map((x) => x.id);
      const currentIndex = transactionIdList.indexOf(activeTransactionId);
      if (currentIndex > 0) dispatch(setActiveTransactionId(transactionIdList[currentIndex - 1]));
    }
  };

  const setNextSplit = () => {
    if (activeTransactionId) {
      const transactionIdList = transaction.map((x) => x.id);
      const currentIndex = transactionIdList.indexOf(activeTransactionId);
      if (currentIndex < transactionIdList.length - 1) dispatch(setActiveTransactionId(transactionIdList[currentIndex + 1]));
    }
  };

  const activeTransaction = transaction.find((x) => x.id === activeTransactionId);

  if (activeTransaction === undefined) return <LoadingModal />;

  return (
    <div className="pos-transaction__order">
      <div className="pos-transaction__header-actions hidden-button">
        <button className="pos-transaction__header-action" type="button">
          Bookings
        </button>
        <button className="pos-transaction__header-action" type="button">
          Loyalty
        </button>
        <button className="pos-transaction__header-action" type="button">
          Tables
        </button>
      </div>
      <div className="pos-transaction__invoice">
        <div className="pos-invoice pos-invoice_role_transaction js-invoice">
          <div className="pos-invoice__panel">
            <div className="pos-invoice__panel-content">
              <div className="pos-invoice__list">
                <div className="pos-invoice__list-labels">
                  <p className="pos-invoice__list-label">Product</p>
                  <p className="pos-invoice__list-label">Qty</p>
                  <p className="pos-invoice__list-label">Each</p>
                  <p className="pos-invoice__list-label">Total</p>
                </div>
                <div className="pos-invoice__list-scroll js-invoice-scroll">
                  <Swiper
                    freeMode={true}
                    height={300}
                    slidesPerView="auto"
                    spaceBetween={30}
                    mousewheel={true}
                    scrollbar={true}
                    direction="vertical"
                    pagination={{ clickable: true }}
                    modules={[FreeMode, Pagination]}
                  >
                    <SwiperSlide>
                      <div className="pos-invoice__items">
                        {activeTransaction.transactionLineItems.map((lineItem) => (
                          <div
                            key={lineItem.id}
                            className={`pos-invoice__item`}
                            onClick={() => dispatch(setActiveTransactionLineItem(lineItem))}
                          >
                            <div className="pos-invoice__item-info">
                              <p className="pos-invoice__item-text">{lineItem.description}</p>
                              <div className="pos-invoice__item-meta">
                                {lineItem.childTransactionLineItems.map((childLineItem) => (
                                  <div key={childLineItem.id} className="pos-invoice__item-meta-group">
                                    <p className="pos-invoice__item-meta-text">
                                      {childLineItem.menuItemId > 0 && (
                                        <>
                                          {childLineItem.description} <Price price={childLineItem.itemPrice} />
                                        </>
                                      )}
                                      {childLineItem.menuItemId === 0 && <i>{childLineItem.description}</i>}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="pos-invoice__item-qty">
                              <p className="pos-invoice__item-text">{lineItem.quantity}</p>
                            </div>
                            <div className="pos-invoice__item-price">
                              <p className="pos-invoice__item-text">
                                <Price price={lineItem.itemPrice} />
                              </p>
                            </div>
                            <div className="pos-invoice__item-total">
                              <p className="pos-invoice__item-text">
                                <Price price={lineItem.totalPrice} />
                              </p>
                            </div>
                          </div>
                        ))}
                        {activeTransaction.transactionAppliedDeals.map((deal) => (
                          <div key={deal.id} className={`pos-invoice__item pos-invoice__item-deal`}>
                            <div className="pos-invoice__item-info">
                              <p className="pos-invoice__item-text">{deal.dealName}</p>
                            </div>
                            <div className="pos-invoice__item-qty"></div>
                            <div className="pos-invoice__item-price">
                              <p className="pos-invoice__item-text"></p>
                            </div>
                            <div className="pos-invoice__item-total">
                              <p className="pos-invoice__item-text">
                                <Price price={-deal.amount} />
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="pos-invoice__panel-footer">
              {activeTransaction.bookingId !== null && (
                <div className="pos-invoice__panel-deposit">
                  <div className="pos-invoice__panel-deposit-info">
                    <p className="pos-invoice__panel-deposit-label">Prepaid Deposit</p>
                    <p className="pos-invoice__panel-deposit-name">{activeTransaction.bookingName}</p>
                  </div>
                  <p className="pos-invoice__panel-deposit-value">
                    -<Price price={activeTransaction.bookingAmount ? activeTransaction.bookingAmount : 0} />
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="pos-invoice__info">
            {activeTransaction.tableId && (
              <p className="pos-invoice__table-number">{activeTransaction.tableNumber ? `T${activeTransaction.tableNumber}` : ''}</p>
            )}
            <p className="pos-invoice__total">
              <span className="pos-invoice__total-label">Total</span>
              <span className="pos-invoice__total-value">
                {activeTransaction.bookingId == undefined && <Price price={activeTransaction.totalValue} />}
                {activeTransaction.bookingId != undefined && (
                  <Price price={-(activeTransaction.bookingAmount ?? 0 - activeTransaction.totalValue)} />
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={`pos-transaction__pagination ${activeTransaction.tableId ? '' : 'hidden-button'}`}>
        <div className="pos-pagination pos-pagination_role_transaction">
          <button
            className="pos-pagination__control pos-pagination__control_role_previous"
            type="button"
            disabled={transaction.length <= 1}
            onClick={setPreviousSplit}
          >
            Previous
          </button>
          <div className="pos-pagination__info">
            <span className="pos-pagination__label">Split</span>
            <span className="pos-pagination__value">
              {activeTransaction.sequenceNumber}/{transaction.length}
            </span>
          </div>
          <button
            className="pos-pagination__control pos-pagination__control_role_next"
            type="button"
            disabled={transaction.length <= 1}
            onClick={setNextSplit}
          >
            Next
          </button>
        </div>
      </div>
      <div className="pos-transaction__actions">
        <button
          className={`pos-transaction__action pos-transaction__action_role_split ${activeTransaction.tableId ? '' : 'hidden-button'}`}
          type="button"
          onClick={() => {
            dispatch(clearSplit());
            navigate('/split');
          }}
          disabled={activeTransaction.cardPaymentInProgress}
        >
          Split
        </button>
        <button
          className="pos-transaction__action pos-transaction__action_role_receipt"
          type="button"
          onClick={receiptClicked}
          disabled={activeTransaction.cardPaymentInProgress}
        >
          Receipt
        </button>
      </div>
    </div>
  );
}

export default TenderOrderPanel;
