import { useCallback, useEffect, useRef, useState } from 'react';

import { useAppDispatch } from '../../../app/redux/hooks';
import { appSubscriptionService } from '../../../common/subscriptions/appSubscriptionService';
import { TransactionLineItemAddRequest, useAddTransactionLineItemMutation } from '../../api';
import { useFeatureFlags } from '../../config';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';
import { setAddToTransactionInProgress, setTransaction, useTransaction } from '../orderSlice';

export default function OrderAddToTransaction() {
  const dispatch = useAppDispatch();
  const addItemList = useRef<TransactionLineItemAddRequest[]>([]);
  const [triggerAddLineItem] = useAddTransactionLineItemMutation();
  const [isAdding, setIsAdding] = useState(false);
  const [addError, setAddError] = useState<string | null>(null);
  const transactionCurrent = useTransaction();
  const flags = useFeatureFlags();
  const useNewFlow = flags && flags.useWebsocketBasketProcess === true;

  const processNextItem = useCallback(async () => {
    if (useNewFlow) {
      if (addItemList.current.length > 0 && !isAdding) {
        setIsAdding(true);
        const transactionItem = addItemList.current.shift();
        if (transactionItem) {
          console.debug('Adding item to transaction', transactionItem);
          try {
            dispatch({
              type: 'signalr/add-item-to-basket',
              payload: {
                transactionId: transactionItem.transactionId,
                model: {
                  menuItemId: transactionItem.menuItemId,
                  unitOfSaleId: transactionItem.unitOfSaleId,
                  quantity: transactionItem.quantity,
                  condiments: transactionItem.condiments.map((x) => ({ menuItemId: x.menuItemId, unitOfSaleId: x.unitOfSaleId })),
                  transactionId: transactionItem.transactionId,
                  addOns: transactionItem.addOns.map((x) => ({ menuItemId: x.menuItemId })),
                  takeOffs: transactionItem.takeOffs.map((x) => ({ menuItemId: x.menuItemId })),
                  wasteReasonId: transactionItem.wasteReasonId,
                  operatorId: transactionItem.operatorId,
                },
              },
            });
          } catch (error) {
            setAddError('Error adding item to transaction.');
          } finally {
            //setIsAdding(false);
            //if (addItemList.current.length > 0) processNextItem();
            //else dispatch(setAddToTransactionInProgress(false));
          }
        }
      }
    } else {
      if (addItemList.current.length > 0 && !isAdding) {
        setIsAdding(true);
        const transactionItem = addItemList.current.shift();
        if (transactionItem) {
          console.debug('Adding item to transaction', transactionItem);
          try {
            await triggerAddLineItem({
              menuItemId: transactionItem.menuItemId,
              unitOfSaleId: transactionItem.unitOfSaleId,
              quantity: transactionItem.quantity,
              condiments: transactionItem.condiments.map((x) => ({ menuItemId: x.menuItemId, unitOfSaleId: x.unitOfSaleId })),
              transactionId: transactionItem.transactionId,
              addOns: transactionItem.addOns.map((x) => ({ menuItemId: x.menuItemId })),
              takeOffs: transactionItem.takeOffs.map((x) => ({ menuItemId: x.menuItemId })),
              wasteReasonId: transactionItem.wasteReasonId,
              operatorId: transactionItem.operatorId,
            })
              .unwrap()
              .then((txNew) => {
                // txNew is the whole transaction
                console.debug('new transaction info after added item', JSON.stringify(txNew));
                if (transactionCurrent.length === 1 && transactionCurrent[0].id === txNew.id) dispatch(setTransaction([txNew]));
              });
          } catch (error) {
            setAddError('Error adding item to transaction.');
          } finally {
            setIsAdding(false);
            if (addItemList.current.length > 0) processNextItem();
            else dispatch(setAddToTransactionInProgress(false));
          }
        }
      }
    }
  }, [useNewFlow, isAdding, triggerAddLineItem, transactionCurrent, dispatch]);

  useEffect(() => {
    if (useNewFlow) {
      // is there anythng in the queue?
      if (isAdding) {
        if (addItemList.current.length > 0) processNextItem();
        else {
          setIsAdding(false);
          dispatch(setAddToTransactionInProgress(false));
        }
      }
    }
  }, [dispatch, isAdding, processNextItem, transactionCurrent, useNewFlow]);

  useEffect(() => {
    const sub = appSubscriptionService.addToBasketObservable().subscribe((val) => {
      dispatch(setAddToTransactionInProgress(true));
      console.debug('Adding item to basket', val);
      addItemList.current.push(val);
      if (!isAdding) processNextItem();
    });
    return () => sub.unsubscribe();
  }, [dispatch, isAdding, processNextItem]);

  useEffect(() => {
    if (addError) {
      dispatch(showErrorDialog({ message: 'Error adding item', dismissible: true }));
      setAddError(null);
    }
  }, [addError, dispatch]);

  if (addItemList.current.length > 0) {
    return (
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100px', background: 'green', zIndex: 9999 }}>
        <ul>
          {addItemList.current.map((item, i) => (
            <li key={i}>{JSON.stringify(item)}</li>
          ))}
        </ul>
      </div>
    );
  }

  return <></>;
}
