import { useEffect, useRef, useState } from 'react';
import { ReactFitty } from 'react-fitty';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import Price from '../../../../common/components/price/Price';
import { appSubscriptionService } from '../../../../common/subscriptions/appSubscriptionService';
import { MenuItem, MenuItemCondiment, MenuItemPrice } from '../../../api';
import { useOperatorSession } from '../../../appState';
import {
  setQuantity,
  setQuantityShow,
  useMenuItemCategories,
  useMenuItemCondiments,
  useMenuItems,
  useQuantity,
  useTransaction,
} from '../../orderSlice';
import './styles.sass';

import placeholder from './assets/placeholder.svg';

interface TransactionItemAdd {
  menuItemId: number;
  unitOfSaleId: number;
  quantity: number;
  transactionId: number;
  operatorId: number;
  condiments: { menuItemId: number; unitOfSaleId: number; condimentId: number }[];
  addOns: { menuItemId: number }[];
  takeOffs: { menuItemId: number }[];
}

const fittyDims = () => {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  let maxWidth = '430px';

  if (windowHeight === 800 && windowWidth === 1280) maxWidth = '473px';
  else if (windowHeight === 768 && windowWidth === 1024) maxWidth = '400px';
  else if (windowHeight === 600 && windowWidth === 1024) maxWidth = '400px';

  if (windowHeight >= 900 && windowHeight <= 905) {
    return {
      min: 30,
      max: 50,
      width: maxWidth,
    };
  } else if (windowHeight == 800) {
    return {
      min: 30,
      max: 45,
      width: maxWidth,
    };
  } else if (windowHeight == 768) {
    return {
      min: 25,
      max: 35,
      width: maxWidth,
    };
  } else if (windowHeight >= 600 && windowHeight <= 605) {
    return {
      min: 25,
      max: 35,
      width: maxWidth,
    };
  }
  return { min: 30, max: 60, width: maxWidth };
};

function AddItem() {
  console.debug('Add loaded');
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const menuItems = useMenuItems();
  const categories = useMenuItemCategories();
  const quantity = useQuantity();
  const condiments = useMenuItemCondiments();
  const transaction = useTransaction();
  const operatorSession = useOperatorSession();

  const isWasteMode = transaction.length > 0 && transaction[0].isWaste === true;

  const itemAddRequested = useRef(false);

  const selectedMenuItemId = parseInt(params.menuItemId ? params.menuItemId : '0');
  const selectedMenuItem = menuItems.find((x) => x.id === selectedMenuItemId);

  const [condimentOffset, setCondimentOffset] = useState(0);
  const [addOnOffset, setAddOnOffset] = useState(0);
  const [takeOffOffset, setTakeOffOffset] = useState(0);

  const noOptions = selectedMenuItem
    ? selectedMenuItem.prices.length === 1 &&
      selectedMenuItem.condiments.length === 0 &&
      selectedMenuItem.addOns.length === 0 &&
      selectedMenuItem.takeOffs.length === 0
    : false;

  let defaultTransactionItem: TransactionItemAdd | undefined = undefined;
  if (noOptions) {
    defaultTransactionItem = {
      condiments: [],
      quantity: quantity,
      menuItemId: selectedMenuItemId,
      unitOfSaleId: selectedMenuItem ? selectedMenuItem.prices[0].unitOfSaleId : 0,
      transactionId: transaction.length > 0 ? transaction[0].id : 0,
      addOns: [],
      takeOffs: [],
      operatorId: operatorSession ? operatorSession.operator.id : 0,
    };
  }

  const [uosIndex, setUosIndex] = useState(0);
  const [selectedMenuItemPrice, setSelectedMenuItemPrice] = useState<MenuItemPrice | undefined>(undefined);
  const [transactionItem, setTransactionItem] = useState<TransactionItemAdd | undefined>(defaultTransactionItem);
  const [activeCondiment, setActiveCondiment] = useState<MenuItemCondiment | undefined>();
  const [validCondiments, setValidCondiments] = useState<MenuItemCondiment[]>([]);
  const [requiredCondimentCount, setRequiredCondimentCount] = useState(0);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(noOptions);
  const [optionView, setOptionView] = useState<'CONDIMENT' | 'ADDON' | 'TAKEOFF'>('CONDIMENT');

  useEffect(() => {
    if (selectedMenuItemPrice !== undefined) {
      setTransactionItem({
        condiments: [],
        quantity: quantity,
        menuItemId: selectedMenuItemPrice.menuItemId,
        unitOfSaleId: selectedMenuItemPrice.unitOfSaleId,
        operatorId: operatorSession ? operatorSession.operator.id : 0,
        transactionId: transaction.length > 0 ? transaction[0].id : 0,
        takeOffs: [],
        addOns: [],
      });

      if (selectedMenuItem !== undefined) {
        const validCondimentsFound = condiments.filter(
          (c) =>
            selectedMenuItem.condiments.filter(
              (f) =>
                f.menuItemCondimentId === c.id &&
                (f.unitOfSaleRestrictions.length === 0 ||
                  f.unitOfSaleRestrictions.find((u) => selectedMenuItemPrice.unitOfSaleId === u.id) !== undefined),
            ).length > 0,
        );
        if (validCondimentsFound.length > 0) {
          // We need to order them
          const validCondimentsOrdered = validCondimentsFound.sort((a, b) => {
            const linkA = selectedMenuItem.condiments.find((p) => p.menuItemCondimentId === a.id);
            const linkB = selectedMenuItem.condiments.find((p) => p.menuItemCondimentId === b.id);

            if (linkA && linkB) {
              const aSort = linkA.sortOrder;
              const bSort = linkB.sortOrder;

              return aSort - bSort;
            }
            return -1;
          });
          setValidCondiments(validCondimentsOrdered);
          const requiredCount = selectedMenuItem.condiments.filter(
            (f) => validCondimentsFound.find((x) => x.id == f.menuItemCondimentId) && f.required === true,
          ).length;
          setRequiredCondimentCount(requiredCount);
        } else {
          setValidCondiments([]);
          setRequiredCondimentCount(0);
        }
      }
    } else setTransactionItem(undefined);
  }, [selectedMenuItemPrice, setTransactionItem, condiments, quantity, selectedMenuItem, transaction, operatorSession]);

  useEffect(() => {
    if (validCondiments.length > 0) setActiveCondiment(validCondiments[0]);
    else setActiveCondiment(undefined);
  }, [validCondiments, setActiveCondiment]);

  useEffect(() => {
    if (transactionItem !== undefined && quantity !== transactionItem.quantity) {
      setTransactionItem({
        ...transactionItem,
        quantity,
      });
    }
  }, [quantity, transactionItem]);

  useEffect(() => {
    if (transactionItem !== undefined && showLoadingSpinner === true) {
      if (isWasteMode === false) {
        if (itemAddRequested.current === false) {
          itemAddRequested.current = true;
          appSubscriptionService.addToBasketNext(transactionItem);
          dispatch(setQuantity(1));
          navigate('../');
        }
      } else {
        navigate('/order/add-waste', {
          state: transactionItem,
        });
      }
    }
  }, [transactionItem, showLoadingSpinner, dispatch, navigate, isWasteMode]);

  useEffect(() => {
    if (
      selectedMenuItem !== undefined &&
      selectedMenuItem.prices !== undefined &&
      selectedMenuItem.prices.length === 1 &&
      selectedMenuItemPrice === undefined
    )
      setSelectedMenuItemPrice(selectedMenuItem.prices[0]);

    // TODO Do we need to do parent or child finding
    if (selectedMenuItem !== undefined && selectedMenuItemPrice === undefined) {
      let category = categories.find((x) => x.id === selectedMenuItem.menuItemCategoryIds[0]);
      if (category && category.defaultUnitOfSaleId == undefined && category.parentCategoryId != null) {
        const parentCategoryId = category.parentCategoryId;
        const parentCategory = categories.find((x) => x.id === parentCategoryId);
        category = parentCategory;
      }
      if (category && category.defaultUnitOfSaleId != undefined) {
        const price = selectedMenuItem.prices.find((x) => category !== undefined && x.unitOfSaleId === category.defaultUnitOfSaleId);
        if (price) setSelectedMenuItemPrice(price);
      }
    }
  }, [selectedMenuItem, setSelectedMenuItemPrice, selectedMenuItemPrice, categories]);

  if (selectedMenuItem === undefined || transaction.length === 0 || transaction.length > 1) return <Navigate to="../" />;
  const setQuantityValue = (quantity: number) => dispatch(setQuantity(quantity));
  const addToTransaction = () => {
    setShowLoadingSpinner(true);
  };

  const clickAddOn = () => {
    setCondimentOffset(0);
    setAddOnOffset(0);
    if (optionView === 'ADDON') setOptionView('CONDIMENT');
    else setOptionView('ADDON');
  };

  const clickTakeOff = () => {
    setCondimentOffset(0);
    setTakeOffOffset(0);
    if (optionView === 'TAKEOFF') setOptionView('CONDIMENT');
    else setOptionView('TAKEOFF');
  };

  const addCondimentItem = (price: MenuItemPrice | undefined, condiment: MenuItemCondiment) => {
    const condimentItem = price ? { menuItemId: price.menuItemId, unitOfSaleId: price.unitOfSaleId, condimentId: condiment.id } : undefined;
    if (transactionItem !== undefined) {
      if (transactionItem.condiments.find((x) => x.condimentId === condiment.id) === undefined) {
        if (condimentItem) {
          const condiments = [...transactionItem.condiments, condimentItem];
          setTransactionItem({
            ...transactionItem,
            condiments,
          });
        }
      } else {
        const condiments = [...transactionItem.condiments.filter((x) => x.condimentId !== condiment.id)];
        if (condimentItem) condiments.push(condimentItem);
        setTransactionItem({
          ...transactionItem,
          condiments,
        });
      }

      if (validCondiments.length > 1 && activeCondiment) {
        const currentCondimentIndex = validCondiments.map((x) => x.id).indexOf(activeCondiment.id);
        if (currentCondimentIndex < validCondiments.length - 1) setActiveCondiment(validCondiments[currentCondimentIndex + 1]);
      }
    }
  };

  const addAddOnItem = (menuItem: MenuItem) => {
    if (transactionItem !== undefined) {
      if (transactionItem.addOns.find((x) => x.menuItemId === menuItem.id) === undefined) {
        const addOns = [...transactionItem.addOns, { menuItemId: menuItem.id }];
        setTransactionItem({
          ...transactionItem,
          addOns,
        });
      }
    }
  };
  const addTakeOffItem = (menuItem: MenuItem) => {
    if (transactionItem !== undefined) {
      if (transactionItem.takeOffs.find((x) => x.menuItemId === menuItem.id) === undefined) {
        const takeOffs = [...transactionItem.takeOffs, { menuItemId: menuItem.id }];
        setTransactionItem({
          ...transactionItem,
          takeOffs,
        });
      }
    }
  };

  const CondimentItem = ({ position }: { position: number }) => {
    const index = position - 1;
    if (selectedMenuItemPrice && activeCondiment) {
      // Check if there is a restriction of items
      const menuItemCondimentLink = selectedMenuItem.condiments.find((x) => x.menuItemCondimentId === activeCondiment.id);
      const hasChoiceLimits = menuItemCondimentLink ? menuItemCondimentLink.hasChoiceLimitation : false;
      // we need to get the menu items from this offset, first filter out zero priced ones, then take
      let visibleMenuItems = activeCondiment.menuItems.filter((x) => x.prices.length > 0).slice(condimentOffset, condimentOffset + 16);
      if (hasChoiceLimits && menuItemCondimentLink)
        visibleMenuItems = visibleMenuItems.filter((p) => menuItemCondimentLink.choiceItems.find((x) => x === p.id) !== undefined);

      if (visibleMenuItems.length >= position) {
        const menuItem = visibleMenuItems[index];
        // do we have a price for this modifier? else, it has only one take it esle ignore.
        let price: MenuItemPrice | undefined = undefined;
        if (menuItem.prices.length === 1) price = menuItem.prices[0];
        else if (menuItem.prices.filter((p) => p.unitOfSaleId === selectedMenuItemPrice.unitOfSaleId).length === 1)
          price = menuItem.prices.find((p) => p.unitOfSaleId === selectedMenuItemPrice.unitOfSaleId);
        if (price) {
          return (
            <div
              className={`pos-item__condiment${
                transactionItem &&
                transactionItem.condiments.find((x) => x.condimentId === activeCondiment.id)?.menuItemId === price.menuItemId
                  ? ' is-active'
                  : ''
              }`}
              onClick={() => addCondimentItem(price, activeCondiment)}
            >
              <p className="pos-item__condiment-text">{menuItem.name}</p>
              <p className="pos-item__condiment-price">
                + <Price price={price.amount} />
              </p>
            </div>
          );
        }
      }
    }
    return <div className="pos-item__condiment hidden-button"></div>;
  };

  const AddOnItem = ({ position }: { position: number }) => {
    const index = position - 1;
    if (selectedMenuItemPrice && selectedMenuItem) {
      console.warn(menuItems.filter((p) => selectedMenuItem.addOns.find((x) => x.addOnMenuItemId === p.id)));
      const visibleAddOns = selectedMenuItem.addOns
        .filter((x) => menuItems.find((y) => y.id === x.addOnMenuItemId)?.currentPrice != undefined)
        .slice(addOnOffset, 16);
      if (visibleAddOns.length >= position) {
        const addOn = visibleAddOns[index];
        const menuItem = menuItems.find((x) => x.id === addOn.addOnMenuItemId);
        if (menuItem) {
          return (
            <div
              className={`pos-item__condiment pos-item__condiment_role_add-on${
                transactionItem && transactionItem.addOns.find((x) => x.menuItemId === menuItem.id) !== undefined ? ' is-active' : ''
              }`}
              onClick={() => addAddOnItem(menuItem)}
            >
              <p className="pos-item__condiment-text">{menuItem.name}</p>
              <p className="pos-item__condiment-price">
                {menuItem.currentPrice && (
                  <>
                    + <Price price={menuItem.currentPrice} />
                  </>
                )}
              </p>
            </div>
          );
        }
      }
    }
    return <div className="pos-item__condiment hidden-button"></div>;
  };

  const TakeOffItem = ({ position }: { position: number }) => {
    const index = position - 1;
    if (selectedMenuItemPrice && selectedMenuItem) {
      const visibleTakeOffs = selectedMenuItem.takeOffs
        .filter((x) => menuItems.find((y) => y.id === x.takeOffMenuItemId)?.currentPrice != undefined)
        .slice(addOnOffset, 16);
      if (visibleTakeOffs.length >= position) {
        const takeOff = visibleTakeOffs[index];
        const menuItem = menuItems.find((x) => x.id === takeOff.takeOffMenuItemId);
        if (menuItem) {
          return (
            <div
              className={`pos-item__condiment pos-item__condiment_role_take-off${
                transactionItem && transactionItem.takeOffs.find((x) => x.menuItemId === menuItem.id) !== undefined ? ' is-active' : ''
              }`}
              onClick={() => addTakeOffItem(menuItem)}
            >
              <p className="pos-item__condiment-text">{menuItem.name}</p>
            </div>
          );
        }
      }
    }
    return <div className="pos-item__condiment hidden-button"></div>;
  };
  if (showLoadingSpinner) return <></>;
  const category = categories.find((x) => x.id === selectedMenuItem.menuItemCategoryIds[0]);
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') {
            dispatch(setQuantityShow(false));
            dispatch(setQuantity(1));
            navigate('/order');
          }
        }}
      >
        <div className="pos-modal pos-modal_role_item">
          <div className="pos-modal__content">
            <div className="pos-item js-item">
              <div className="pos-item__header">
                <div className="pos-item__image">
                  <img src={placeholder} alt="Item image" />
                </div>
                <div className="pos-item__info">
                  <h2 className="pos-item__title">
                    <span className="pos-item__title-text js-item-title-text">
                      <ReactFitty style={{ width: fittyDims().width }} minSize={fittyDims().min} maxSize={fittyDims().max}>
                        {selectedMenuItem.name}
                      </ReactFitty>
                    </span>
                  </h2>
                  <p className="pos-item__subtitle">{category?.name}</p>
                </div>

                <div className="pos-item__details">
                  <div className="pos-item__meta">
                    {optionView === 'CONDIMENT' && (
                      <>
                        <p className="pos-item__meta-label">{activeCondiment ? 'Condiment:' : ''}</p>
                        <p className="pos-item__meta-text">{activeCondiment ? activeCondiment.name : ''}</p>
                        <p className="pos-item__meta-text">
                          {activeCondiment
                            ? `${validCondiments.map((x) => x.id).indexOf(activeCondiment.id) + 1}/${validCondiments.length}`
                            : ''}
                        </p>
                      </>
                    )}
                    <p className="pos-item__meta-text">{optionView !== 'CONDIMENT' ? optionView : ''}</p>
                  </div>
                </div>
              </div>
              <div className="pos-item__options js-item-options">
                <div className="pos-item__modifiers js-menu-modifiers">
                  <button
                    className="pos-item__modifiers-control pos-item__modifiers-control_role_previous js-item-modifiers-previous"
                    type="button"
                    disabled={uosIndex === 0}
                  >
                    Previous
                  </button>
                  <div className="pos-item__modifiers-list">
                    <div className="pos-item__modifiers-scroll js-item-modifiers-scroll">
                      <div className="pos-item__modifiers-row">
                        {selectedMenuItem.prices.map((price) => (
                          <div
                            key={price.id}
                            className={`pos-item__modifier ${
                              price?.unitOfSaleCssClass
                                ? price.unitOfSaleCssClass
                                : `pos-item__modifier_role_${price.unitOfSaleName.toLowerCase()}`
                            } ${selectedMenuItemPrice ? (selectedMenuItemPrice.id === price.id ? ' is-active' : '') : ''}`}
                            onClick={() => {
                              setSelectedMenuItemPrice(price);
                              setCondimentOffset(0);
                              setAddOnOffset(0);
                              setTakeOffOffset(0);
                            }}
                          >
                            <p className="pos-item__modifier-text">{price.unitOfSaleName}</p>
                            <div className="pos-item__modifier-group">
                              <div className="pos-item__modifier-price">
                                <Price price={price.amount} />
                              </div>
                              <div className="pos-item__modifier-icon"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <button
                    className="pos-item__modifiers-control pos-item__modifiers-control_role_next js-item-modifiers-next"
                    type="button"
                    onClick={() => setUosIndex(uosIndex + 3)}
                    disabled={uosIndex >= selectedMenuItem.prices.length || selectedMenuItem.prices.length < 4}
                  >
                    Next
                  </button>
                </div>
                {optionView === 'CONDIMENT' && (
                  <div className="pos-item__condiments">
                    <div className="pos-item__condiments-row">
                      <CondimentItem position={1} />
                      <CondimentItem position={2} />
                      <CondimentItem position={3} />
                      <CondimentItem position={4} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <CondimentItem position={5} />
                      <CondimentItem position={6} />
                      <CondimentItem position={7} />
                      <CondimentItem position={8} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <CondimentItem position={9} />
                      <CondimentItem position={10} />
                      <CondimentItem position={11} />
                      <CondimentItem position={12} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <CondimentItem position={13} />
                      <CondimentItem position={14} />
                      <CondimentItem position={15} />
                      {selectedMenuItem.condiments.find((x) => x.menuItemCondimentId === activeCondiment?.id)?.required === true &&
                        validCondiments.length === 1 && <CondimentItem position={16} />}
                      {activeCondiment &&
                        selectedMenuItem.condiments.find((x) => x.menuItemCondimentId === activeCondiment?.id)?.required === false &&
                        validCondiments.length > 1 && (
                          <div className={`pos-item__condiment`} onClick={() => addCondimentItem(undefined, activeCondiment)}>
                            <p className="pos-item__condiment-text">NONE</p>
                            <p className="pos-item__condiment-price">+ £0.00</p>
                          </div>
                        )}
                    </div>
                    <div className="pos-item__condiments-row">
                      <button
                        disabled={condimentOffset === 0}
                        className="pos-item__condiments-control pos-item__condiments-control_role_previous"
                        type="button"
                        onClick={() => setCondimentOffset(condimentOffset - 16)}
                      >
                        Previous
                      </button>
                      <button
                        className={`pos-item__condiments-action pos-item__condiments-action_role_take-off ${
                          selectedMenuItem.takeOffs.length === 0 ? 'hidden-button' : ''
                        }`}
                        type="button"
                        onClick={() => clickTakeOff()}
                      >
                        - Take Off
                      </button>
                      <button
                        className={`pos-item__condiments-action pos-item__condiments-action_role_add-on ${
                          selectedMenuItem.addOns.length === 0 ? 'hidden-button' : ''
                        }`}
                        type="button"
                        onClick={() => clickAddOn()}
                      >
                        + Add On
                      </button>
                      <button
                        className="pos-item__condiments-control pos-item__condiments-control_role_next"
                        type="button"
                        disabled={activeCondiment === undefined || condimentOffset + 16 > activeCondiment.menuItems.length}
                        onClick={() => setCondimentOffset(condimentOffset + 16)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
                {optionView === 'ADDON' && (
                  <div className="pos-item__condiments">
                    <div className="pos-item__condiments-row">
                      <AddOnItem position={1} />
                      <AddOnItem position={2} />
                      <AddOnItem position={3} />
                      <AddOnItem position={4} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <AddOnItem position={5} />
                      <AddOnItem position={6} />
                      <AddOnItem position={7} />
                      <AddOnItem position={8} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <AddOnItem position={9} />
                      <AddOnItem position={10} />
                      <AddOnItem position={11} />
                      <AddOnItem position={12} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <AddOnItem position={13} />
                      <AddOnItem position={14} />
                      <AddOnItem position={15} />
                      <AddOnItem position={16} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <button
                        disabled={addOnOffset === 0}
                        className="pos-item__condiments-control pos-item__condiments-control_role_previous"
                        type="button"
                        onClick={() => setAddOnOffset(addOnOffset - 16)}
                      >
                        Previous
                      </button>
                      <button
                        className={`pos-item__condiments-action pos-item__condiments-action_role_take-off ${
                          selectedMenuItem.takeOffs.length === 0 ? 'hidden-button' : ''
                        }`}
                        type="button"
                        onClick={() => clickTakeOff()}
                      >
                        - Take Off
                      </button>
                      <button
                        className="pos-item__condiments-action pos-item__condiments-action_role_add-on"
                        type="button"
                        onClick={() => clickAddOn()}
                      >
                        Back
                      </button>
                      <button
                        className="pos-item__condiments-control pos-item__condiments-control_role_next"
                        type="button"
                        disabled={addOnOffset + 16 > selectedMenuItem.addOns.length}
                        onClick={() => setAddOnOffset(addOnOffset + 16)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {optionView === 'TAKEOFF' && (
                  <div className="pos-item__condiments">
                    <div className="pos-item__condiments-row">
                      <TakeOffItem position={1} />
                      <TakeOffItem position={2} />
                      <TakeOffItem position={3} />
                      <TakeOffItem position={4} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <TakeOffItem position={5} />
                      <TakeOffItem position={6} />
                      <TakeOffItem position={7} />
                      <TakeOffItem position={8} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <TakeOffItem position={9} />
                      <TakeOffItem position={10} />
                      <TakeOffItem position={11} />
                      <TakeOffItem position={12} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <TakeOffItem position={13} />
                      <TakeOffItem position={14} />
                      <TakeOffItem position={15} />
                      <TakeOffItem position={16} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <button
                        disabled={takeOffOffset === 0}
                        className="pos-item__condiments-control pos-item__condiments-control_role_previous"
                        type="button"
                        onClick={() => setTakeOffOffset(takeOffOffset - 16)}
                      >
                        Previous
                      </button>
                      <button
                        className={`pos-item__condiments-action pos-item__condiments-action_role_take-off`}
                        type="button"
                        onClick={() => clickTakeOff()}
                      >
                        Back
                      </button>
                      <button
                        className={`pos-item__condiments-action pos-item__condiments-action_role_add-on ${
                          selectedMenuItem.addOns.length === 0 ? 'hidden-button' : ''
                        }`}
                        type="button"
                        onClick={() => clickAddOn()}
                      >
                        + Add On
                      </button>
                      <button
                        className="pos-item__condiments-control pos-item__condiments-control_role_next"
                        type="button"
                        disabled={takeOffOffset + 16 > selectedMenuItem.addOns.length}
                        onClick={() => setTakeOffOffset(takeOffOffset + 16)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="pos-item__quantity">
                <p className="pos-item__quantity-label">QTY</p>
                <div className="pos-quantity pos-quantity_role_item">
                  <button
                    className="pos-quantity__control"
                    type="button"
                    onClick={() => setQuantityValue(quantity - 1)}
                    disabled={quantity === 1}
                  >
                    -
                  </button>
                  <div className="pos-quantity__value">{quantity}</div>
                  <button className="pos-quantity__control" type="button" onClick={() => setQuantityValue(quantity + 1)}>
                    +
                  </button>
                </div>
              </div>
              <div className="pos-item__meta">
                <p className="pos-item__meta-label"></p>
                <p className="pos-item__meta-text">
                  <button
                    disabled={
                      selectedMenuItemPrice === undefined || (transactionItem && transactionItem.condiments.length < requiredCondimentCount)
                    }
                    className="pos-item__add-button"
                    onClick={addToTransaction}
                  >
                    Add
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItem;
