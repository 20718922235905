import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';
import { TenderMedia } from '../api';

export interface TenderState {
  tenderMedias: TenderMedia[];
  activeTransactionId?: number | undefined;
  cardPaymentResult?: string | undefined;
  cardManualEntryLog?: boolean;
}

const initialState: TenderState = {
  tenderMedias: [],
};

const tenderSlice = createSlice({
  name: 'tender',
  initialState,
  reducers: {
    setTenderMedias: (state: Draft<TenderState>, { payload }: PayloadAction<TenderMedia[]>) => {
      state.tenderMedias = payload;
    },
    setActiveTransactionId: (state: Draft<TenderState>, { payload }: PayloadAction<number>) => {
      state.activeTransactionId = payload;
    },
    setCardPaymentSent: (state: Draft<TenderState>, { payload }: PayloadAction<string | undefined>) => {
      state.cardPaymentResult = payload;
      state.cardManualEntryLog = false;
    },
    clearTenderStatus: (state: Draft<TenderState>) => {
      state.activeTransactionId = undefined;
      state.cardPaymentResult = undefined;
      state.cardManualEntryLog = false;
    },
    setCardManualEntryLog: (state: Draft<TenderState>, { payload }: PayloadAction<boolean>) => {
      state.cardManualEntryLog = payload;
    },
  },
});
export const { setTenderMedias, setActiveTransactionId, setCardPaymentSent, clearTenderStatus, setCardManualEntryLog } =
  tenderSlice.actions;

export default tenderSlice.reducer;
const selectTenderMedias = (state: RootState) => state.tender.tenderMedias;
const selectActiveTransactionId = (state: RootState) => state.tender.activeTransactionId;
const selectCardPaymentResult = (state: RootState) => state.tender.cardPaymentResult;
const selectCardManualEntryLog = (state: RootState) => state.tender.cardManualEntryLog;
export const useTenderMedias = () => useAppSelector(selectTenderMedias);
export const useActiveTransactionId = () => useAppSelector(selectActiveTransactionId);
export const useCardPaymentResult = () => useAppSelector(selectCardPaymentResult);
export const useCardManualEntryLog = () => useAppSelector(selectCardManualEntryLog);
