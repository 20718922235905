import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { EngineInformation, OperatorSession, Site, Workstation } from '../api';

import { ApplicationState } from './ApplicationState';

const initialState: ApplicationState = {
  signalrConnected: false,
  site: undefined,
  operatorSession: undefined,
  workstation: undefined,
  engineInfo: undefined,
  workstationInitialised: false,
  serverLastPolled: 'Never',
  menuDataChanged: 0,
  stateLoadErrors: [],
  layoutDefinitionVersionLastPolled: 0,
  packageVersionLastPolled: 0,
  menuVersionLastPolled: 0,
} as ApplicationState;

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setWorkstation: (state: Draft<ApplicationState>, action: PayloadAction<Workstation>) => {
      state.workstation = action.payload;
    },
    setOperatorSession: (state: Draft<ApplicationState>, action: PayloadAction<OperatorSession>) => {
      state.operatorSession = action.payload;
    },
    clearOperatorSession: (state: Draft<ApplicationState>) => {
      state.operatorSession = undefined;
    },
    setSignalrStatus: (state: Draft<ApplicationState>, action: PayloadAction<boolean>) => {
      state.signalrConnected = action.payload;
    },
    setSite: (state: Draft<ApplicationState>, action: PayloadAction<Site>) => {
      state.site = action.payload;
    },
    setEngineInfo: (state: Draft<ApplicationState>, action: PayloadAction<EngineInformation>) => {
      state.engineInfo = action.payload;
    },
    setServerLastPolled: (state: Draft<ApplicationState>, action: PayloadAction<string>) => {
      state.serverLastPolled = action.payload;
    },
    setPollData: (
      state: Draft<ApplicationState>,
      action: PayloadAction<{ date: string; layoutDefinitionVersion: number; menuVersion: number; packageVersion: number }>,
    ) => {
      state.serverLastPolled = action.payload.date;
      state.layoutDefinitionVersionLastPolled = action.payload.layoutDefinitionVersion;
      state.packageVersionLastPolled = action.payload.packageVersion;
      state.menuVersionLastPolled = action.payload.menuVersion;
    },
    setMenuDataChanged: (state: Draft<ApplicationState>) => {
      state.menuDataChanged = new Date().getTime();
    },
    addLoadError: (state: Draft<ApplicationState>, action: PayloadAction<string>) => {
      state.stateLoadErrors.push(action.payload);
    },
    setTrainingModeSource: (state: Draft<ApplicationState>, action: PayloadAction<string>) => {
      state.trainingModeSourceUrl = action.payload;
    },
    setWorkstationBlocked: (state: Draft<ApplicationState>, action: PayloadAction<boolean>) => {
      if (state.workstation) state.workstation.isSessionBlockNewTransactions = action.payload;
    },
    setWorkstationClosed: (state: Draft<ApplicationState>, action: PayloadAction<boolean>) => {
      if (state.workstation) state.workstation.hasSession = action.payload;
    },
  },
});
export const {
  setWorkstation,
  clearOperatorSession,
  setOperatorSession,
  setSignalrStatus,
  setSite,
  setEngineInfo,
  setServerLastPolled,
  setMenuDataChanged,
  setTrainingModeSource,
  addLoadError,
  setWorkstationBlocked,
  setWorkstationClosed,
  setPollData,
} = appStateSlice.actions;

export default appStateSlice.reducer;
