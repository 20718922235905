import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';

export interface ErrorDialogMessage {
  errorType?: string;
  message: string;
  dismissible: boolean;
  buttonText?: string;
  backgroundColor?: string;
  alternateButtonText?: string;
  showAlternateButton?: boolean;
  requireConfirmationOnClose?: boolean;
  confirmationText?: string;
  confirmationTextAlternate?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  alternateState?: any;
}

export interface ErrorDialogState {
  error?: ErrorDialogMessage | undefined;
  visible: boolean;
}

const initialState: ErrorDialogState = {
  visible: false,
};

const errorDialogSlice = createSlice({
  name: 'errorDialog',
  initialState,
  reducers: {
    showErrorDialog: (state: Draft<ErrorDialogState>, { payload }: PayloadAction<ErrorDialogMessage>) => {
      state.error = payload;
      state.visible = true;
    },
    clearErrorDialog: (state) => {
      state.error = undefined;
      state.visible = false;
    },
  },
});

export const reducerName = errorDialogSlice.name;
export const { showErrorDialog, clearErrorDialog } = errorDialogSlice.actions;

export default errorDialogSlice.reducer;

const selectErrorDialogMessage = (state: RootState) => state.errorDialog.error;

export const useErrorDialogMessage = () => useAppSelector(selectErrorDialogMessage);
