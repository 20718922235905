import { baseApi } from '../baseApi';
import { Transaction } from '../transaction/Transaction';

import { TransactionLineItemAddRequest } from './TransactionLineItemAddRequest';
import { TransactionLineItemMessageRequest } from './TransactionLineItemMessageRequest';
import { TransactionLineItemVoidRequest } from './TransactionLineItemVoidRequest';

const transactionLineItemSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Transactions'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      addTransactionLineItem: builder.mutation<Transaction, TransactionLineItemAddRequest>({
        query: (payload: TransactionLineItemAddRequest) => ({
          url: `/TransactionLineItems/Add/${payload.transactionId}`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Transactions'],
      }),
      addTransactionLineItemMessage: builder.mutation<void, TransactionLineItemMessageRequest>({
        query: (payload: TransactionLineItemMessageRequest) => ({
          url: `/TransactionLineItems/Message/${payload.transactionLineItemId}`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Transactions'],
      }),
      voidTransactionLineItem: builder.mutation<void, TransactionLineItemVoidRequest>({
        query: (payload: TransactionLineItemVoidRequest) => ({
          url: `/TransactionLineItems/Void?transactionLineItemId=${payload.transactionLineItemId}&voidReasonId=${payload.voidReasonId}&voidOperatorId=${payload.voidOperatorId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Transactions'],
      }),
    }),
  });

export const { useAddTransactionLineItemMessageMutation, useAddTransactionLineItemMutation, useVoidTransactionLineItemMutation } =
  transactionLineItemSlice;
