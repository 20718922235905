import { useCallback, useEffect, useRef } from 'react';

import { useAppDispatch } from '../../../app/redux/hooks';
import logger from '../../../common/logger/AppLogger';
import { OperatorLevel, useLazyOperatorSessionGetByFobQuery } from '../../api';
import { setOperatorSession } from '../../appState';
import { useTrainingModeUrl } from '../../config';
import { clearErrorDialog, showErrorDialog } from '../../error-dialog/errorDialogSlice';

export function TrainingModeFob() {
  const dispatch = useAppDispatch();

  const trainingModeUrl = useTrainingModeUrl();

  const [triggerFobCheck] = useLazyOperatorSessionGetByFobQuery();

  const keyboardState = useRef('');

  const signOn = useCallback(
    (fob: string) => {
      triggerFobCheck(fob)
        .unwrap()
        .then((signOnResult) => {
          logger.info('Sign On Result', signOnResult);
          if (
            signOnResult.operator.operatorLevel === OperatorLevel.Admin ||
            signOnResult.operator.operatorLevel === OperatorLevel.Manager
          ) {
            dispatch(setOperatorSession(signOnResult));
            window.location.href = `${trainingModeUrl}?trainingMode=true&source=${window.location.origin}`;
          } else {
            dispatch(
              showErrorDialog({
                message: 'You are not authorized to Training Mode',
                dismissible: true,
              }),
            );
          }
        })
        .catch((err) => {
          if (err && err.status === 404) {
            dispatch(
              showErrorDialog({
                message: 'Fob is not assigned to an active session.',
                dismissible: true,
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'Fob Validation Error',
                dismissible: true,
              }),
            );
          }
        });
    },
    [dispatch, triggerFobCheck, trainingModeUrl],
  );

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOn = /^0100([a-z|A-Z|0-9]{12})$/gm;
        const regexSignOff = /^0{3,4}(00)$/gm;
        const regexSignOnMatch = keyboardState.current.match(regexSignOn);
        if (regexSignOnMatch !== null) signOn(regexSignOnMatch[0]);

        const regexSignOffMatch = keyboardState.current.match(regexSignOff);
        if (regexSignOffMatch !== null) dispatch(clearErrorDialog());

        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };

    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [dispatch, signOn]);

  return (
    <div className="pos-modal__content">
      <div className="pos-sign-in js-sign-in">
        <div className="pos-sign-in__header">
          <h2 className="pos-sign-in__title">Manager Validation</h2>
        </div>
        <div className="pos-sign-in__info">
          <p className="pos-sign-in__name"></p>
          <p className="pos-sign-in__label">Please place your FOB on the FOB reader to enable training mode</p>
        </div>
      </div>
    </div>
  );
}
