/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../app/redux/hooks';
import AppLogger from '../../common/logger/AppLogger';
import { appSubscriptionService } from '../../common/subscriptions/appSubscriptionService';
import { PinEntryDevice, useConfirmEftFailureMutation } from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';

import CardFailureConfirmationAmount from './CardFailureConfirmationAmount';
import CardFailureConfirmationChoosePed from './CardFailureConfirmationChoosePed';
import CardFailureConfirmationFinal from './CardFailureConfirmationFinal';
import './styles.sass';

export default function CardFailureConfirmation() {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<any>();
  const [step, setStep] = useState(0);
  const [ped, setPed] = useState<PinEntryDevice | undefined>();
  const [amount, setAmount] = useState(0);
  const operatorSession = useOperatorSession();

  const [triggerConfirmEftFailure] = useConfirmEftFailureMutation();

  useEffect(() => {
    const sub = appSubscriptionService.errorButtonClickedObservable().subscribe((val: any) => {
      if (val && val.type === 'card-failure') {
        setState(val);
        setStep(1);
      }
    });
    return () => sub.unsubscribe();
  }, [setState]);

  const onConfirm = () => {
    if (ped && amount) {
      triggerConfirmEftFailure({
        pinEntryDeviceId: ped.id,
        transactionId: state.transactionId,
        operatorId: operatorSession?.operator.id ?? 0,
        confirmHasFailed: true,
        lastSuccessfulAmount: amount,
      })
        .unwrap()
        .then(() => {
          setState(undefined);
          setStep(0);
          setPed(undefined);
          setAmount(0);
        })
        .catch((err) => {
          AppLogger.error(err);
          dispatch(
            showErrorDialog({
              message: 'Failed to confirm payment',
              dismissible: true,
              buttonText: 'OK',
            }),
          );
        });
    }
  };

  const onCancelWindow = () => {
    setState(undefined);
    setStep(0);
    setPed(undefined);
    setAmount(0);

    dispatch(
      showErrorDialog({
        errorType: 'card-payment-failure',
        message: state.message,
        dismissible: true,
        buttonText: 'Payment Failed',
        backgroundColor: 'red',
        alternateButtonText: 'Payment Completed',
        showAlternateButton: true,
        requireConfirmationOnClose: true,
        confirmationText: 'Please confirm this payment did NOT succeed. You will need to resolve the issue.',
        confirmationTextAlternate: 'Please confirm the payment succeeded, you will need to enter the Auth Code off the card machine.',
        state: {
          transactionId: state.transactionId,
          transactionNumber: state.transactionNumber,
          sequenceNumber: state.sequenceNumber,
          operatorName: state.operatorName,
          amount: state.amount,
          reason: state.reason,
          cardRequestId: state.cardRequestId,
          message: state.message,
          type: 'card-failure',
        },
        alternateState: {
          transactionId: state.transactionId,
          transactionNumber: state.transactionNumber,
          sequenceNumber: state.sequenceNumber,
          operatorName: state.operatorName,
          amount: state.amount,
          reason: state.reason,
          cardRequestId: state.cardRequestId,
          message: state.message,
          type: 'offline-card',
        },
      }),
    );
  };

  if (state == undefined) return <></>;

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') onCancelWindow();
        }}
      >
        {step === 1 && (
          <CardFailureConfirmationChoosePed
            selectedPed={(pedGot) => {
              setPed(pedGot);
              setStep(2);
            }}
          />
        )}
        {step === 2 && (
          <CardFailureConfirmationAmount
            onAmountConfirmed={(amount: number) => {
              setAmount(amount);
              setStep(3);
            }}
          />
        )}
        {step === 3 && ped && <CardFailureConfirmationFinal onClick={onConfirm} amount={amount} ped={ped} />}
      </div>
    </div>
  );
}
