import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import LoadingModal from '../../common/components/loading-modal/LoadingModal';
import Price from '../../common/components/price/Price';
import logger from '../../common/logger/AppLogger';
import { PaymentMethodType, Transaction, useLazyGetTransactionQuery, useOperatorSignOffMutation } from '../api';
import { clearOperatorSession, useOperatorSession } from '../appState';
import { clearOrderState } from '../order/orderSlice';

import { clearTenderStatus, useCardPaymentResult, useTenderMedias } from './tenderSlice';

import './summary.sass';

function Summary() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const keyboardState = useRef('');
  const cardPaymentResult = useCardPaymentResult();
  const [cardResult, setCardResult] = useState<string | undefined>();
  const operatorSession = useOperatorSession();
  const transactionId = params.transactionId ? parseInt(params.transactionId) : 0;
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [triggerGet] = useLazyGetTransactionQuery();

  const [triggerSignOff] = useOperatorSignOffMutation();
  const tenderMedias = useTenderMedias();
  const cashTender = tenderMedias.find((x) => x.paymentMethodType === PaymentMethodType.Cash);
  const cardTenders = tenderMedias.filter((x) => x.paymentMethodType === PaymentMethodType.Card);
  const loyaltyTenders = tenderMedias.filter((x) => x.name.toLowerCase().indexOf('loyalty') > -1);
  const depositTender = tenderMedias.find((x) => x.paymentMethodType === PaymentMethodType.Deposit);

  const signOff = useCallback(() => {
    if (operatorSession !== undefined) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          dispatch(clearTenderStatus());
          navigate('/');
        })
        .catch(() => {
          clearOperatorSession();
          navigate('/');
        });
    }
  }, [dispatch, navigate, operatorSession, triggerSignOff]);

  useEffect(() => {
    if (state && transaction === null) {
      // we got the transaction from state, not set here yet
      setTransaction(state);
    } else {
      triggerGet(transactionId)
        .unwrap()
        .then((data) => {
          setTransaction(data);
        });
    }
  }, [state, transaction, transactionId, triggerGet]);

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOff = /^0{3,4}(00)$/gm;
        if (operatorSession === undefined) navigate('/');
        else {
          const regexSignOffMatch = keyboardState.current.match(regexSignOff);
          if (regexSignOffMatch !== null) {
            logger.info('Sign Off needed');
            signOff();
          }
        }

        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };
    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [operatorSession, signOff, dispatch, navigate]);

  useEffect(() => {
    if (cardPaymentResult !== undefined) setCardResult(cardPaymentResult);
  }, [dispatch, cardPaymentResult]);
  if (!transaction) return <LoadingModal />;
  const change = transaction.transactionPayments.reduce((a, b) => a + b.change, 0);
  return (
    <div className="pos-container pos-container_role_summary">
      <div className="pos-summary">
        <div className="pos-summary__data">
          <div className="pos-data pos-data_role_summary">
            <div className="pos-data__list">
              <div className="pos-data__item pos-data__item_role_total">
                <p className="pos-data__item-label">{transaction.isRefund ? 'Refund' : 'Sale'} Total</p>
                <p className="pos-data__item-value">
                  <Price price={transaction.totalValue} />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_cash">
                <p className="pos-data__item-label">Cash</p>
                <p className="pos-data__item-value">
                  <Price
                    price={transaction.transactionPayments
                      .filter((x) => cashTender !== undefined && cashTender.id === x.tenderMediaId)
                      .reduce((a, b) => a + b.tendered, 0)}
                  />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_card">
                <p className="pos-data__item-label">Card</p>
                <p className="pos-data__item-value">
                  {' '}
                  {transaction.isRefund && (
                    <Price
                      price={transaction.transactionPayments
                        .filter(
                          (x) => cardTenders.length > 0 && cardTenders.find((y) => y.id == x.tenderMediaId) !== undefined && x.tendered < 0,
                        )
                        .reduce((a, b) => a + b.tendered, 0)}
                    />
                  )}
                  {transaction.isRefund == false && (
                    <Price
                      price={transaction.transactionPayments
                        .filter((x) => cardTenders.length > 0 && cardTenders.find((y) => y.id == x.tenderMediaId) !== undefined)
                        .reduce((a, b) => a + b.tendered, 0)}
                    />
                  )}
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_loyalty">
                <p className="pos-data__item-label">Loyalty</p>
                <p className="pos-data__item-value">
                  {' '}
                  <Price
                    price={transaction.transactionPayments
                      .filter((x) => loyaltyTenders.find((y) => y.id === x.tenderMediaId) !== undefined)
                      .reduce((a, b) => a + b.tendered, 0)}
                  />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_deposit">
                <p className="pos-data__item-label">Deposit</p>
                <p className="pos-data__item-value">
                  {' '}
                  <Price
                    price={transaction.transactionPayments
                      .filter((x) => depositTender !== undefined && depositTender.id === x.tenderMediaId)
                      .reduce((a, b) => a + b.tendered, 0)}
                  />
                </p>
              </div>
              <div className="pos-data__item pos-data__item_role_change">
                <p className="pos-data__item-label">Change Due</p>
                <p className="pos-data__item-value">{change > 0 ? <Price price={change} /> : <>No Change</>}</p>
              </div>
              {cardResult && (
                <div className="pos-data__item pos-data__item_role_cardresult card-status">
                  <p className="pos-data__item-label">CARD PAYMENT RESULT WAS {cardResult}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pos-summary__actions">
          <button className="pos-summary__action" type="button" onClick={() => navigate('/order')}>
            Press to Continue
          </button>
        </div>
      </div>
    </div>
  );
}
export default Summary;
