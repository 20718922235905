import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import logger from '../../common/logger/AppLogger';
import { PinEntryDevice, useCardTransactionMutation, useGetPinEntryDevicesQuery } from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { setTransaction, useTransaction } from '../order/orderSlice';

function PinEntryDeviceModal() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const transactionId = Number(state.activeTransactionId ?? '0');

  const operator = useOperatorSession();
  const { data: pinEntryDevices, isLoading: pinEntryDevicesLoading } = useGetPinEntryDevicesQuery(false);
  const transaction = useTransaction();
  const [requestCardEft] = useCardTransactionMutation();

  const [processingCard, setProcessingCard] = useState(false);
  const requestCardPayment = (terminal: PinEntryDevice) => {
    if (processingCard === false) {
      setProcessingCard(true);
      if (transactionId > 0 && operator) {
        const amount = state.amount > 0 ? state.amount : null;
        requestCardEft({
          pinEntryDeviceId: terminal.id,
          transactionId: transactionId,
          operatorId: operator.operator.id,
          amount: amount,
        })
          .unwrap()
          .then((tx) => {
            const otherTx = transaction.filter((p) => p.id !== tx.id);
            const newTx = [...otherTx, tx];
            dispatch(setTransaction(newTx));
            navigate('../');
          })
          .catch((err) => {
            logger.error(err);
            if (err && err.status === 412) {
              dispatch(
                showErrorDialog({
                  message: 'Card Payment already in progress for transaction',
                  dismissible: true,
                  buttonText: 'Close',
                }),
              );
              navigate('../');
            } else if (err && err.status === 424) {
              dispatch(
                showErrorDialog({
                  message: err.data,
                  dismissible: true,
                  buttonText: 'Close',
                }),
              );
              navigate('../');
            } else {
              dispatch(
                showErrorDialog({
                  message: 'An error occurred requesting payment',
                  dismissible: true,
                  buttonText: 'Close',
                }),
              );
              navigate('../');
            }
          });
      }
    }
  };

  const PinEntryDevice = ({ position }: { position: number }) => {
    const index = position - 1;
    if (pinEntryDevices && pinEntryDevices.length >= position) {
      const pinEntryDevice = pinEntryDevices[index];
      return (
        <div className={`pos-pin-entry-devices__device`} onClick={() => requestCardPayment(pinEntryDevice)}>
          <h3 className="pos-pin-entry-devices__device-text">{pinEntryDevice.name}</h3>
          <p className="pos-pin-entry-devices__device-meta">
            <span className="pos-pin-entry-devices__device-meta-line">{pinEntryDevice.terminalId}</span>
          </p>
        </div>
      );
    }
    return <div className="pos-pin-entry-devices__device hidden-button"></div>;
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('../');
        }}
      >
        <div className="pos-modal pos-modal_role_pin-entry-devices">
          <div className="pos-modal__content">
            <div className="pos-pin-entry-devices js-pin-entry-devices">
              <div className="pos-pin-entry-devices__header">
                <h2 className="pos-pin-entry-devices__title">Pin Entry Devices</h2>
                <p className="pos-pin-entry-devices__subtitle">Please select a PED from the list below:</p>
              </div>
              {pinEntryDevicesLoading == false && pinEntryDevices?.length == 0 && (
                <div className="pos-pin-entry-devices__empty">No Pin Entry Devices are available</div>
              )}
              <div className="pos-pin-entry-devices__info">
                <div className="pos-pin-entry-devices__devices">
                  <div className="pos-pin-entry-devices__devices-list">
                    <div className="pos-pin-entry-devices__devices-scroll js-pin-entry-devices-servers-scroll">
                      <div className="pos-pin-entry-devices__devices-row">
                        <div className="pos-pin-entry-devices__devices-group">
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={1} />
                            <PinEntryDevice position={2} />
                            <PinEntryDevice position={3} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={4} />
                            <PinEntryDevice position={5} />
                            <PinEntryDevice position={6} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={7} />
                            <PinEntryDevice position={8} />
                            <PinEntryDevice position={9} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={10} />
                            <PinEntryDevice position={11} />
                            <PinEntryDevice position={12} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={13} />
                            <PinEntryDevice position={14} />
                            <PinEntryDevice position={15} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={16} />
                            <PinEntryDevice position={17} />
                            <PinEntryDevice position={18} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PinEntryDeviceModal;
