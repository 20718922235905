import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch } from '../../../../app/redux/hooks';
import Price from '../../../../common/components/price/Price';
import { LoyaltyType } from '../../../api';
import { setActiveTransactionLineItem, useQuantity, useQuantityShown, useTransaction } from '../../orderSlice';

import 'swiper/css/bundle';

function TransactionPanel({ showFullHeight, onTotalClicked }: { showFullHeight: boolean; onTotalClicked: () => void }) {
  const dispatch = useAppDispatch();
  const quantity = useQuantity();
  const showQuantity = useQuantityShown();
  const transaction = useTransaction();
  const primaryTransaction = transaction.length > 0 ? transaction[0] : undefined;
  if (primaryTransaction === undefined) return <></>;

  return (
    <div className="pos-main__invoice">
      <div className="pos-invoice pos-invoice_role_main js-invoice">
        <div className={`pos-invoice__panel ${showFullHeight ? 'pos-invoice__panel-full' : ''}`}>
          <div className="pos-invoice__panel-content">
            <div className="pos-invoice__list">
              <div className="pos-invoice__list-labels">
                <p className="pos-invoice__list-label">Product</p>
                <p className="pos-invoice__list-label">Qty</p>
                <p className="pos-invoice__list-label">Each</p>
                <p className="pos-invoice__list-label">Total</p>
              </div>
              <div className="pos-invoice__list-scroll js-invoice-scroll">
                <Swiper
                  freeMode={true}
                  height={300}
                  slidesPerView="auto"
                  spaceBetween={30}
                  mousewheel={true}
                  scrollbar={true}
                  direction="vertical"
                  pagination={{ clickable: true }}
                  modules={[FreeMode, Pagination]}
                >
                  <SwiperSlide>
                    <div className="pos-invoice__items">
                      {primaryTransaction.transactionLineItems.map((lineItem) => (
                        <div
                          key={lineItem.id}
                          className={`pos-invoice__item ${lineItem.orderDeviceFired ? 'pos-item-is-order-device-fired' : ''}`}
                          onClick={() => dispatch(setActiveTransactionLineItem(lineItem))}
                        >
                          <div className="pos-invoice__item-info">
                            <p className="pos-invoice__item-text">{lineItem.description}</p>
                            <div className="pos-invoice__item-meta">
                              {lineItem.childTransactionLineItems.map((childLineItem) => (
                                <div key={childLineItem.id} className="pos-invoice__item-meta-group">
                                  <p className="pos-invoice__item-meta-text">
                                    {childLineItem.menuItemId > 0 && (
                                      <>
                                        {childLineItem.description} <Price price={childLineItem.itemPrice} />
                                      </>
                                    )}
                                    {childLineItem.menuItemId === 0 && <i>{childLineItem.description}</i>}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="pos-invoice__item-qty">
                            <p className="pos-invoice__item-text">{lineItem.quantity}</p>
                          </div>
                          <div className="pos-invoice__item-price">
                            <p className="pos-invoice__item-text">
                              <Price price={lineItem.itemPrice} />
                            </p>
                          </div>
                          <div className="pos-invoice__item-total">
                            <p className="pos-invoice__item-text">
                              <Price price={lineItem.totalPrice} />
                            </p>
                          </div>
                        </div>
                      ))}
                      {primaryTransaction.transactionAppliedDeals.map((deal) => (
                        <div key={deal.id} className={`pos-invoice__item pos-invoice__item-deal`}>
                          <div className="pos-invoice__item-info">
                            <p className="pos-invoice__item-text">{deal.dealName}</p>
                          </div>
                          <div className="pos-invoice__item-qty"></div>
                          <div className="pos-invoice__item-price">
                            <p className="pos-invoice__item-text"></p>
                          </div>
                          <div className="pos-invoice__item-total">
                            <p className="pos-invoice__item-text">
                              <Price price={-deal.amount} />
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <div className="pos-invoice__panel-footer">
            {primaryTransaction.bookingId !== null && (
              <div className="pos-invoice__panel-deposit">
                <div className="pos-invoice__panel-deposit-info">
                  <p className="pos-invoice__panel-deposit-label">Prepaid Deposit</p>
                  <p className="pos-invoice__panel-deposit-name">{primaryTransaction.bookingName}</p>
                </div>
                <p className="pos-invoice__panel-deposit-value">
                  -<Price price={primaryTransaction.bookingAmount ? primaryTransaction.bookingAmount : 0} />
                </p>
              </div>
            )}
            {primaryTransaction.loyaltyType != LoyaltyType.None && (
              <div className="pos-invoice__panel-deposit">
                <div className="pos-invoice__panel-deposit-info">
                  <p className="pos-invoice__panel-deposit-label">
                    Loyalty Linked
                    {primaryTransaction.loyaltyType === LoyaltyType.Customer && <span> - Customer</span>}
                    {primaryTransaction.loyaltyType === LoyaltyType.PromoCode && <span> - Voucher</span>}
                  </p>
                </div>
              </div>
            )}
            <p className="pos-invoice__panel-function">{showQuantity ? `x${quantity}` : ''}</p>
          </div>
        </div>
        <div className="pos-invoice__info">
          {primaryTransaction.tableNumber && <p className="pos-invoice__table-number">{primaryTransaction.tableNumber}</p>}
          <p className="pos-invoice__total">
            <span className="pos-invoice__total-label">
              Total{' '}
              <span className="item-count">({primaryTransaction.transactionLineItems.reduce((a, b) => a + b.quantity, 0)} items)</span>
            </span>
            <span className="pos-invoice__total-value">
              <div onClick={onTotalClicked}>
                {primaryTransaction.bookingId == undefined && <Price price={primaryTransaction.totalValue} />}
                {primaryTransaction.bookingId != undefined && (
                  <Price price={-((primaryTransaction.bookingAmount ?? 0) - primaryTransaction.totalValue)} />
                )}
              </div>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default TransactionPanel;
