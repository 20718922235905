import { useAppSelector } from '../../app/redux/hooks';
import { RootState } from '../../app/redux/reducer';

export const selectConfig = (state: RootState) => state.config.configuration;
export const selectOauthConfig = (state: RootState) => state.config.configuration?.oAuth;
export const selectApiConfig = (state: RootState) => state.config.configuration?.api;
export const selectVersion = (state: RootState) => state.config.version;
const selectTrainingModeUrl = (state: RootState) => state.config.configuration?.app.trainingModeUrl;
const selectShowEnvironmentBanner = (state: RootState) => state.config.configuration?.app.showEnvironmentBanner;
const selectFeatureFlags = (state: RootState) => state.config.configuration?.featureFlags;
export const useShowEnvironmentBanner = () => useAppSelector(selectShowEnvironmentBanner);
export const useFeatureFlags = () => useAppSelector(selectFeatureFlags);
export const useTrainingModeUrl = () => useAppSelector(selectTrainingModeUrl);
