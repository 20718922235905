/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import Price from '../../common/components/price/Price';
import { appSubscriptionService } from '../../common/subscriptions/appSubscriptionService';
import { TransactionStatus, useCheckEftManuallyMutation, useOfflineCardTenderTransactionMutation } from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { clearOrderState, setTransaction } from '../order/orderSlice';

export default function OfflineCardPayment() {
  const navigate = useNavigate();
  const [authCode, setAuthCode] = useState<string>('');
  const dispatch = useAppDispatch();

  const operatorSession = useOperatorSession();

  const [state, setState] = useState<any>();

  useEffect(() => {
    const sub = appSubscriptionService.errorButtonAlternateClickedObservable().subscribe((val: any) => {
      if (val && val.type === 'offline-card') setState(val);
    });
    return () => sub.unsubscribe();
  }, [setState]);

  const activeTransactionId = state?.transactionId;
  const amount = state?.amount;

  const [triggerManualCardPayment, { isLoading }] = useOfflineCardTenderTransactionMutation();
  const [manualEftCheck] = useCheckEftManuallyMutation();

  const onCancelWindow = () => {
    dispatch(
      showErrorDialog({
        errorType: 'card-payment-failure',
        message: state.message,
        dismissible: true,
        buttonText: 'Payment Failed',
        backgroundColor: 'red',
        alternateButtonText: 'Payment Completed',
        showAlternateButton: true,
        requireConfirmationOnClose: true,
        confirmationText: 'Please confirm this payment did NOT succeed. You will need to resolve the issue.',
        confirmationTextAlternate: 'Please confirm the payment succeeded, you will need to enter the Auth Code off the card machine.',
        state: {
          transactionId: state.transactionId,
          transactionNumber: state.transactionNumber,
          sequenceNumber: state.sequenceNumber,
          operatorName: state.operatorName,
          amount: state.amount,
          reason: state.reason,
          cardRequestId: state.cardRequestId,
          message: state.message,
          type: 'card-failure',
        },
        alternateState: {
          transactionId: state.transactionId,
          transactionNumber: state.transactionNumber,
          sequenceNumber: state.sequenceNumber,
          operatorName: state.operatorName,
          amount: state.amount,
          reason: state.reason,
          cardRequestId: state.cardRequestId,
          message: state.message,
          type: 'offline-card',
        },
      }),
    );
    setState(undefined);
  };

  const checkDojo = () => {
    if (activeTransactionId && state.cardRequestId) {
      manualEftCheck({ transactionId: activeTransactionId, cardRequestId: state.cardRequestId })
        .unwrap()
        .then(() => {
          setAuthCode('');
          setState(undefined);
          return;
        })
        .catch(() => {
          dispatch(showErrorDialog({ message: 'DOJO did not find a suitable payment, please fill in manually.', dismissible: true }));
        });
    }
  };

  const tenderOffline = () => {
    if (authCode.length != 6) {
      dispatch(
        showErrorDialog({
          message: 'The Auth Code must be 6 digits',
          dismissible: true,
          buttonText: 'OK',
        }),
      );
    } else {
      if (activeTransactionId) {
        triggerManualCardPayment({
          transactionId: activeTransactionId,
          amount: amount,
          authCode: authCode,
          cardRequestId: state.cardRequestId,
        })
          .unwrap()
          .then((data) => {
            setAuthCode('');
            if (data.transaction.status === TransactionStatus.Closed) {
              dispatch(setTransaction([]));
              dispatch(clearOrderState());
              setState(undefined);
              if (operatorSession) navigate(`/tender/summary/${data.transaction.id}`);
            } else {
              setState(undefined);
              navigate('/tender');
            }
          })
          .catch((err) => {
            if (err.status === 418) {
              dispatch(
                showErrorDialog({
                  message: err.data.error,
                  dismissible: true,
                  buttonText: 'OK',
                }),
              );
              setAuthCode('');
            } else {
              dispatch(
                showErrorDialog({ message: err.data && err.data.detail ? err.data.detail : JSON.stringify(err), dismissible: true }),
              );
              setAuthCode('');
              setState(undefined);
            }
          });
      }
    }
  };

  if (state == undefined) return <></>;

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') onCancelWindow();
        }}
      >
        <div className="pos-modal pos-modal_role_sign-in">
          <div className="pos-modal__content">
            <div className="pos-sign-in js-sign-in">
              <div className="pos-sign-in__header">
                <h2 className="pos-sign-in__title">Offline Card Payment</h2>
                <p className="pos-sign-in__subtitle">Please enter the Auth Code off the Card Machine</p>
                <p className="pos-sign-in__note">*** clicking out of this window will mean the payment did not complete ***</p>
              </div>
              <div className="pos-sign-in__info">
                <p className="pos-sign-in__name">
                  Payment Value: <Price price={state.amount} />
                </p>
              </div>
              <div className="pos-sign-in__controls">
                <div className="pos-sign-in__pin" style={{ marginBottom: '15px' }}>
                  <div className="pos-field pos-field_role_pin">
                    <div className="pos-field__group">
                      <div className="pos-field__box" style={{ fontSize: '18px', padding: '0', paddingLeft: '20px' }}>
                        {authCode}
                      </div>
                      <button className="pos-field__action" onClick={tenderOffline} disabled={authCode.length != 6 || isLoading}>
                        Enter
                      </button>
                    </div>
                  </div>
                </div>
                <div className="pos-sign-in__keyboard">
                  <div className="pos-keyboard pos-keyboard_role_sign-in">
                    <div className="pos-keyboard__controls">
                      <div className="pos-keyboard__controls-row">
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          1
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          2
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          3
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          4
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          5
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          6
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          7
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          8
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          9
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          0
                        </button>
                      </div>
                      <div className="pos-keyboard__controls-row">
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          q
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          w
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          e
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          r
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          t
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          y
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          u
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          i
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          o
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          p
                        </button>
                      </div>
                      <div className="pos-keyboard__controls-row">
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          a
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          s
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          d
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          f
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          g
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          h
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          j
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          k
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          l
                        </button>
                      </div>
                      <div className="pos-keyboard__controls-row">
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          z
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          x
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          c
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          v
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          b
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          n
                        </button>
                        <button
                          className="pos-keyboard__control"
                          type="button"
                          onClick={(e) => setAuthCode(authCode + (e.target as any).textContent.toUpperCase())}
                        >
                          m
                        </button>
                        <button
                          className="pos-keyboard__control pos-keyboard__control_role_backspace"
                          type="button"
                          onClick={() => setAuthCode(authCode.length > 0 ? authCode.substring(0, authCode.length - 1) : '')}
                        >
                          Backspace
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="pos-new-table__action"
                  style={{ margin: '0 auto', marginTop: '10px', height: '50px', lineHeight: '50px', backgroundColor: '#c17a7a' }}
                  type="button"
                  onClick={checkDojo}
                >
                  Re-Check DOJO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
